import
{
    UPDATE_VACANCY_LOADING,
    UPDATE_VACANCY_SUCCESS,
    UPDATE_VACANCY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateVacancyEndpoint } from "api/apiUrls";
import { notification } from "antd";
import { updateProtectedItemService } from "services/updateProtectedItemService";

export const updateVacancyAction = (id: string, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_VACANCY_LOADING, payload: true});
        const response = await updateProtectedItemService(`${updateVacancyEndpoint}?vacancyId=${id}`, data, "json");
        dispatch({type: UPDATE_VACANCY_SUCCESS, payload: response.data});
        if (response.status === 200) {
            notification.success({
                message: (response.data.message === "" || response.data.message === null || response.data.message === undefined) ? "Success" : response.data.message
            });
        }
        else {
            notification.error({
                message: (response.data.message === "" || response.data.message === null || response.data.message === undefined) ? "Something went wrong" : response.data.message
            });
        }
    }   
    catch (err) {
        dispatch({type: UPDATE_VACANCY_FAILURE, payload: err});
    }
    finally {
        dispatch({type: UPDATE_VACANCY_LOADING, payload: false});
    }
}