import
{
    PASSWORD_TWO_LOADING,
    PASSWORD_TWO_SUCCESS,
    PASSWORD_TWO_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { forgotPasswordTwoEndpoint } from "api/apiUrls";

export const forgotPasswordTwoAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: PASSWORD_TWO_LOADING, payload: true});
        const response = await createNewItemService(forgotPasswordTwoEndpoint, data, "json");
        dispatch({type: PASSWORD_TWO_SUCCESS, payload: response.data});
        if (response.status === 200) {
            callback && callback();
        }
    }   
    catch (err) {
        dispatch({type: PASSWORD_TWO_FAILURE, payload: err});
    }
    finally {   
        dispatch({type: PASSWORD_TWO_LOADING, payload: false});
    }
}