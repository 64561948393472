import { useFormik } from "formik"
import * as yup from "yup";
import CreateVacancyModel from "models/CreateVacancyModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { updateVacancyAction } from "redux/actions/vacancyActions/updateVacancyAction";

const useUpdateVacancyFormik = () => {
    const dispatch: any = useDispatch();

    const { vacancy } = useSelector((state: RootState) => state.listSingleVacancyReducer);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            areaId: vacancy?.areaId,
            cityId: vacancy?.cityId,
            countryId: vacancy?.countryId,
            deadline: vacancy?.deadline,
            description: vacancy?.description,
            companyFieldId: vacancy?.companyFieldId,
            requirements: vacancy?.requirements,
            responsibilities: vacancy?.responsbilities,
            skillIds: vacancy?.vacancySkills?.map((item: any) => item?.skillId),
            title: vacancy?.title,
            vacancyLevelId: vacancy?.vacancyLevelId,
            VacancyWorkPlaceId: vacancy?.vacancyWorkPlaceId,
        },
        validationSchema: yup.object({
            cityId: yup.string().required(DEFAULT_ERROR_MSG),
            countryId: yup.string().required(DEFAULT_ERROR_MSG),
            title: yup.string().required(DEFAULT_ERROR_MSG),
            requirements: yup.string().required(DEFAULT_ERROR_MSG),
            responsibilities: yup.string().required(DEFAULT_ERROR_MSG),
            vacancyLevelId: yup.string().required(DEFAULT_ERROR_MSG),
            deadline: yup.string().required(DEFAULT_ERROR_MSG),
            description: yup.string().required(DEFAULT_ERROR_MSG),
            areaId: yup.string().required(DEFAULT_ERROR_MSG),
            companyFieldId: yup.string().required(DEFAULT_ERROR_MSG),
            VacancyWorkPlaceId: yup.string().required(DEFAULT_ERROR_MSG)
        }),
        onSubmit: async (values: CreateVacancyModel) => {
            await dispatch(updateVacancyAction(
                vacancy?.vacancyId,
                {
                    countryId: values.countryId,
                    cityId: values.cityId,
                    title: values.title,
                    responsbilities: values.responsibilities,
                    vacancyLevelId: values.vacancyLevelId,
                    requirements: values.requirements,
                    deadline: values.deadline,
                    skillIds: values.skillIds,
                    description: values.description,
                    areaId: values.areaId,
                    companyFieldId: values.companyFieldId,
                    vacancyWorkPlaceId: values.VacancyWorkPlaceId
                }
            ));
        }
    });

    return formik;
}

export {
    useUpdateVacancyFormik
}