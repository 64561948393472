import api from "api";
import store from "redux/store";

export const updateProtectedItemService = async (url: string, data: any, contentType: "json" | "formData") => {
    const response = await api.put(
        url, 
        data,
        {
            headers: {
                "Authorization": `Bearer ${store.getState()?.stepOneReducer?.token}`,
                "Content-Type": contentType === "json" ? "application/json" : "multipart/form-data"
            }
        }
    );

    return response;
}