import { useFormik } from "formik"
import * as yup from "yup";
import ForgotPasswordTwoModel from "models/ForgotPasswordTwoModel";
import { DEFAULT_ERROR_MSG } from "constants/index";
import { useDispatch } from "react-redux";
import { forgotPasswordThreeAction } from "redux/actions/forgotPasswordActions/forgotPasswordThreeAction";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { notification } from "antd";

const useForgotPasswordTwo = (setState: React.Dispatch<React.SetStateAction<string>>, email: string) => {
    const dispatch: any = useDispatch();

    const { data } = useSelector((state: RootState) => state.forgotPasswordTwoReducer);

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmNewPassword: ''
        },
        validationSchema: yup.object({
            newPassword:  yup.string()
            .min(8, "password must be at least 8 characters long")
            .matches(
              new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*-_?&#]{8,}$/
              ),
              "Must contain lowercase, uppercase, number, and one special character"
            )
            .required("This field is required"),
            confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match').required(DEFAULT_ERROR_MSG)
        }),
        onSubmit: async (values: ForgotPasswordTwoModel) => {
           await dispatch(forgotPasswordThreeAction({
            resetToken: data.data,
            email: email,
            newPassword: values.newPassword
           }, () => {
            setState("");
            notification.success({
                message: 'Password Reset Successfully'
            });
           }));
        }
    });

    return formik;
}

export {
    useForgotPasswordTwo
}