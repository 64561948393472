import { Input, notification, Spin } from "antd";
import { DefaultImage, RoundedClose, WideDefaultImage } from "assets/icons";
import { useEffect, useState } from "react";
import InputField from "../ui/InputField";
import FormSubmitButton from "../ui/FormSubmitButton";
import { useStepOneFormik } from "forms/stepOneFormik";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { genOtpAction } from "redux/actions/genOtpAction";
import { confirmOtpAction } from "redux/actions/confirmOtpAction";

const StepOne = ({
  setActiveModal,
}: {
  setActiveModal: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch: any = useDispatch();

  const [profileImage, setProfileImage] = useState<any>(null);
  const [coverImage, setCoverImage] = useState<any>(null);
  const [showOtp, setShowOtp] = useState(false);
  const [disableOtp, setDisableOtp] = useState(false);
  const [otpConfirmed, setOtpConfirmed] = useState(false);
  const [sendOtpDisabled, setSendOtpDisabled] = useState(false);

  const [otp, setOtp] = useState("");

  const { stepOneLoading } = useSelector(
    (state: RootState) => state.stepOneReducer
  );
  const { genOtpLoading } = useSelector(
    (state: RootState) => state.genOtpReducer
  );
  const { confirmLoading } = useSelector(
    (state: RootState) => state.confirmOtpReducer
  );

  const formik = useStepOneFormik(setActiveModal);

  const handleSendOtp = () => {
    if (formik.values.Email === "" || formik.errors.Email) {
      notification.error({
        message:
          formik.values.Email === ""
            ? "Enter your email first"
            : formik.errors.Email,
      });
      return;
    }
    dispatch(
      genOtpAction(
        {
          email: formik.values.Email,
        },
        () => {
          notification.success({
            message: "OTP sent to your mail",
          });
          setShowOtp(true);
          setSendOtpDisabled(true);
        }
      )
    );
  };

  const handleConfirmOtp = async () => {
    if (otp === "" || otp.length !== 4) {
      notification.error({
        message: "Invalid Otp",
      });
      return;
    }
    await dispatch(
      confirmOtpAction(
        {
          email: formik.values.Email,
          otp: otp,
        },
        () => {
          setOtpConfirmed(true);
        }
      )
    );
  };

  return (
    <div className="fixed top-0 right-0 w-full h-screen bg-black/50 flex items-center justify-center z-10">
      <Spin spinning={false} wrapperClassName="modal-spin-container">
        <div className="relative max-h-[90vh] lg:w-[410px] p-[10px] bg-modalBg rounded-[15px] flex flex-col items-center gap-[15px] overflow-y-auto">
          <div className="scroll w-full h-full flex flex-col items-center gap-[15px] overflow-y-auto py-5 px-[20px]">
            <button
              onClick={() => setActiveModal("")}
              className="block lg:hidden w-[2rem] h-[2rem] self-end"
            >
              <img src={RoundedClose} alt="close" />
            </button>
            <button
              onClick={() => setActiveModal("")}
              className="hidden lg:block w-[2rem] h-[2rem] absolute right-5"
            >
              <img src={RoundedClose} alt="close" />
            </button>
            <p className="text-base scrn-391:text-xl text-white font-bold">
              fill company name and image
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex flex-col items-center gap-y-4"
            >
              <div className="flex justify-center">
                {!profileImage ? (
                  <img src={DefaultImage} alt="profile" />
                ) : (
                  <img
                    className="w-[120px] h-[120px] rounded-full object-cover"
                    src={URL.createObjectURL(profileImage)}
                    alt="profile"
                  />
                )}
              </div>
              <div className="w-full flex justify-center text-white">
                <input
                  type="file"
                  id="custom-input"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const validExtensions = ["png", "jpg"];
                      const fileExtension = file.name.split(".").pop();
                      if (!validExtensions.includes(fileExtension ?? "")) {
                        notification.error({
                          message: "File extension must be .png, or .jpg",
                        });
                        return false;
                      }
                      setProfileImage(file);
                      formik.setFieldValue("ProfileLogo", file);
                    }
                  }}
                  hidden
                />
                <label
                  htmlFor={"custom-input"}
                  className="text-[16px] cursor-pointer"
                >
                  Upload Profile Image
                </label>
              </div>
              <div className="flex justify-center">
                {!coverImage ? (
                  <img src={WideDefaultImage} alt="cover" />
                ) : (
                  <img
                    className="w-[360px] h-[120px] rounded-[15px] object-cover"
                    src={URL.createObjectURL(coverImage)}
                    alt="cover"
                  />
                )}
              </div>
              <div className="w-full flex justify-center text-white">
                <input
                  type="file"
                  id="custom-input-2"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const validExtensions = ["png", "jpg"];
                      const fileExtension = file.name.split(".").pop();
                      if (!validExtensions.includes(fileExtension ?? "")) {
                        notification.error({
                          message: "File extension must be .png, or .jpg",
                        });
                        return false;
                      }
                      setCoverImage(file);
                      formik.setFieldValue("CoverLogo", file);
                    }
                  }}
                  hidden
                />
                <label
                  htmlFor={"custom-input-2"}
                  className="text-[16px] cursor-pointer"
                >
                  Upload Cover Image
                </label>
              </div>
              <InputField
                name="CompanyName"
                placeholder="Company Name"
                type="text"
                value={formik.values.CompanyName}
                onChange={formik.handleChange}
                errors={formik.errors.CompanyName}
              />
              <div className="w-full flex items-center gap-x-3">
                <div className="w-[70%]">
                  <InputField
                    name="Email"
                    placeholder="Email"
                    type="email"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    errors={formik.errors.Email}
                    disabled={sendOtpDisabled}
                  />
                </div>
                <Spin spinning={genOtpLoading} wrapperClassName="spin-30">
                  <button
                    onClick={handleSendOtp}
                    type="button"
                    disabled={sendOtpDisabled}
                    className={`w-full h-[2.8rem] bg-[#DCDEE3] text-[14px] rounded-[15px] font-bold duration-100 ${
                      sendOtpDisabled
                        ? "opacity-50 hover:cursor-not-allowed"
                        : "hover:bg-main hover:text-secondary"
                    }`}
                  >
                    Send Code
                  </button>
                </Spin>
              </div>
              {showOtp && (
                <div className="w-full flex flex-col items-center gap-y-4">
                  <Input.OTP
                    length={4}
                    disabled={disableOtp}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e);
                      setDisableOtp(true);
                    }}
                  />
                  {disableOtp && (
                    <a
                      onClick={() => {
                        setOtp("");
                        setDisableOtp(false);
                      }}
                      className="text-white text-sm"
                    >
                      Edit OTP
                    </a>
                  )}
                  <Spin
                    spinning={confirmLoading}
                    wrapperClassName="full-width-spin"
                  >
                    <div className="w-full flex justify-center">
                      <button
                        onClick={handleConfirmOtp}
                        type="button"
                        className="w-[75%] h-[2.8rem] bg-[#DCDEE3] text-[14px] rounded-[15px] font-bold duration-100 hover:bg-main hover:text-secondary"
                      >
                        Confirm
                      </button>
                    </div>
                  </Spin>
                </div>
              )}
              <a className="hover:text-secondary" onClick={() => setSendOtpDisabled(false)}>
                Change email
              </a>
              <InputField
                name="Password"
                placeholder="Password"
                type="password"
                value={formik.values.Password}
                onChange={formik.handleChange}
                errors={formik.errors.Password}
              />
              <InputField
                name="ConfirmPassword"
                placeholder="Confirm Password"
                type="password"
                value={formik.values.ConfirmPassword}
                onChange={formik.handleChange}
                errors={formik.errors.ConfirmPassword}
              />
              <FormSubmitButton
                body="Next"
                loading={stepOneLoading}
                disabled={!otpConfirmed}
              />
            </form>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default StepOne;
