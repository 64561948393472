
export const loginEndpoint = "Auth/Login";
export const stepOneEndpoint = "Auth/companyRegister";
export const stepTwoEndpoint = "Companies/AddCompanyInfo";
export const stepThreeEndpoint = "Companies/AddMediaLinks";
export const completeAuthEndpoint = "Auth/complete";
export const checkCompletionEndpoint = "Auth/UserData";
export const generateOtpEndpoint = "Auth/generate-otp-Email"
export const confirmOtpEndpoint = "Auth/validate-otp-Email";
export const listUserDataEndpoint = "Companies/myCompanyData";
export const updateUserDataEndpoint = "Companies/EditCompanyProfile";

/* Util Endpoints */
export const listCompanyMajorsEndpoint = "CompanyFields";
export const listCountriesEndpoint = "Countries/GetAll";
export const listCitiesEndpoint = "Cities/GetCitiesBy";
export const listAreasEndpoint = "Areas/GetByCityId";
export const listSkillsEndpoint = "Skills/GetAll";

/* Vacancy Endpoints */
export const createVacancyEndpoint = "Vacancies/AddVacancy";
export const listVacanciesEndpoint = "Companies/companyVacancies";
export const updateVacancyEndpoint = "Vacancies/EditVacancy";
export const listSingleVacancyEndpoint = "Vacancies/GetVacancyBy";
export const deleteVacancyEndpoint = "Vacancies/DeleteVacancyBy";

/* Application Endpoints */
export const listVacancyApplicationsEndpoint = "Application/GetApplicationByVacancyId";
export const listSingleApplicationEndpoint = "NewApplication/GetApplication";
export const listApplicantEndpoint = "NewUserProfiles/GetUserProfileByUserIdForCompany";
export const updateApplicationStatusEndpoint = "NewApplication/UpdateApplicationStatus";

/* Forgot Password Endpoints */
export const forgotPasswordOneEndpoint = "Auth/generate-otp-resetPassword";
export const forgotPasswordTwoEndpoint = "Auth/validate-otp-resetPassword";
export const forgotPasswordThreeEndpoint = "Auth/reset-password";

/* Get Subscription Plans Endpoint */
export const getSubscriptionPlansEndpoint = "SubscriptionPlan/GetAll";