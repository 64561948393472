import { Input } from "antd";
import { FC } from "react";
import { ErrorIcon } from "assets/icons";

type InputFieldProps = {
  placeholder: string;
  name: string;
  onChange?: any;
  errors?: any;
  type?: string;
  value?: string;
  disabled?: boolean;
};

const InputField: FC<InputFieldProps> = ({
  placeholder,
  name,
  onChange,
  errors,
  type = "text",
  value,
  disabled = false,
}) => {
  const { Password } = Input;

  return (
    <div className="w-full flex flex-col items-start gap-y-2">
      {type === "password" ? (
        <Password
          placeholder={placeholder}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          className="!h-[3rem] w-full bg-transparent rounded-[15px] !caret-white text-white placeholder:!text-white !border-white !border"
        />
      ) : (
        <Input
          placeholder={placeholder}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={`!h-[3rem] w-full bg-transparent rounded-[15px] !caret-white text-white placeholder:!text-white !border-white !border ${
            disabled ? "opacity-50" : "opacity-100"
          }`}
        />
      )}
      {errors && (
        <p className="text-error animate-pulse flex items-center gap-x-2">
          <img src={ErrorIcon} width={20} height={20} />
          {errors}
        </p>
      )}
    </div>
  );
};

export default InputField;
