import
{
    UPDATE_PROFILE_LOADING,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateUserDataEndpoint } from "api/apiUrls";
import { updateProtectedItemService } from "services/updateProtectedItemService";

export const updateProfileAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_PROFILE_LOADING, payload: true});
        const response = await updateProtectedItemService(updateUserDataEndpoint, data, "formData");
        dispatch({type: UPDATE_PROFILE_SUCCESS, payload: response.data});
        callback && callback();
    }
    catch (err: any) {
        dispatch({type: UPDATE_PROFILE_FAILURE, payload: err});
    }
    finally {
        dispatch({type: UPDATE_PROFILE_LOADING, payload: false});
    }
} 