import { InfoCircleOutlined } from "@ant-design/icons";
import { DatePicker, Input, Select, Spin, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { DownArrowBlack } from "assets/icons";
import { JOB_LOCATION, JOB_TYPES } from "constants/index";
import { useDispatch } from "react-redux";
import { listCitiesAction } from "redux/actions/utilActions/listCitiesAction";
import { useDropdown } from "hooks/useDropdown";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect, useState } from "react";
import { listAreasAction } from "redux/actions/utilActions/listAreasAction";
import { useCreateVacancyFormik } from "forms/createVacancyFormik";
import { listSkillsAction } from "redux/actions/utilActions/listSkillsAction";
import dayjs from "dayjs";

const CreateVacancy = () => {
  const dispatch: any = useDispatch();

  const formik = useCreateVacancyFormik();

  const { countriesLoading } = useSelector(
    (state: RootState) => state.listCountriesReducer
  );
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );
  const { majorsLoading } = useSelector(
    (state: RootState) => state.listMajorsReducer
  );
  const { areasLoading, areas } = useSelector(
    (state: RootState) => state.listAreasReducer
  );
  const { createVacancyLoading } = useSelector(
    (state: RootState) => state.createVacancyReducer
  );
  const { skillsLoading, skills } = useSelector(
    (state: RootState) => state.listSkillsReducer
  );

  const { countriesDropdown, majorsDropdown } = useDropdown();

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.cityNameEn,
      value: item.cityId,
    };
  });

  const areasDropdown =
    Array.isArray(areas) &&
    areas?.map((item: any) => {
      return {
        label: item.areaNameEn,
        value: item.areaId,
      };
    });

  const skillsDropdown = skills?.map((item: any) => {
    return {
      label: item?.skillNameEn,
      value: item?.skillId,
    };
  });

  const [selectedJobLocationType, setSelectedJobLocationType] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  const [editorState, setEditorState] = useState<any>();
  const [responsibiliesHtml, setResponsibilitiesHtml] = useState<any>();

  const [editorState2, setEditorState2] = useState<any>();
  const [jobRequirementsHtml, setJobRequirementsHtml] = useState<any>();

  useEffect(() => {
    dispatch(listCitiesAction(1));
    dispatch(listAreasAction(1));
    dispatch(listSkillsAction());
  }, []);

  useEffect(() => {
    if (editorState) {
      formik.setFieldValue(
        "responsibilities",
        convertToHTML(editorState?.getCurrentContent())
      );
    }
  }, [editorState]);

  useEffect(() => {
    if (editorState2) {
      formik.setFieldValue(
        "requirements",
        convertToHTML(editorState2?.getCurrentContent())
      );
    }
  }, [editorState2]);

  return (
    <div className="w-full h-full flex flex-col gap-y-10 px-10 py-10 mb-10">
      <p className="font-semibold text-2xl">Create Vacancy Form</p>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full h-full flex flex-col gap-y-5"
      >
        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">Position Title</label>
          <Input
            placeholder="Position Title"
            name="title"
            className="md:w-[70%] lg:w-[50%] !h-[2.8rem] !border-black"
            onChange={formik.handleChange}
          />
          {formik.touched.title && formik.errors.title && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.title}
            </p>
          )}
        </div>
        <div className="dashboard-input w-full flex flex-col items-start">
          <label className="font-Avenir_regular text-lg">
            Position Description
          </label>
          <TextArea
            autoSize={{ minRows: 6, maxRows: 10 }}
            placeholder="Position Description"
            name="description"
            className="w-[50%] !border-black overflow-hidden"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          {formik.touched.description && formik.errors.description && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.description}
            </p>
          )}
        </div>
        <div className="w-full flex flex-row items-center justify-start gap-x-10">
          <div className="w-[50%] flex flex-col items-start justify-center">
            <label className="font-Avenir_regular text-lg">Country</label>
            <Spin
              wrapperClassName="full-width-spin"
              spinning={countriesLoading}
            >
              <Select
                className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
                placeholder="Country"
                suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
                options={countriesDropdown}
                onSelect={(value: any) => {
                  formik.setFieldValue("countryId", value);
                  dispatch(listCitiesAction(value?.toString()));
                }}
              />
            </Spin>
            {formik.touched.countryId && formik.errors.countryId && (
              <p className="text-[red] font-Avenir_regular">
                {formik.errors.countryId}
              </p>
            )}
          </div>
          <div className="w-[50%] flex flex-col items-start justify-center">
            <label className="font-Avenir_regular text-lg">City</label>
            <Spin wrapperClassName="full-width-spin" spinning={citiesLoading}>
              <Select
                className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
                placeholder="City"
                suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
                options={citiesDropdown}
                onSelect={(value: any) => {
                  formik.setFieldValue("cityId", value);
                  formik.setFieldValue("areaId", null);
                  dispatch(listAreasAction(value.toString()));
                }}
              />
            </Spin>
            {formik.touched.cityId && formik.errors.cityId && (
              <p className="text-[red] font-Avenir_regular">
                {formik.errors.cityId}
              </p>
            )}
          </div>
        </div>
        <div className="w-[50%] flex flex-col items-start justify-center">
          <label className="font-Avenir_regular text-lg">Area</label>
          <Spin wrapperClassName="full-width-spin" spinning={areasLoading}>
            <Select
              className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Area"
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              options={
                areasDropdown ? areasDropdown : [{ label: "", value: "" }]
              }
              onSelect={(value: any) => {
                formik.setFieldValue("areaId", value);
              }}
            />
          </Spin>
          {formik.touched.areaId && formik.errors.areaId && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.areaId}
            </p>
          )}
        </div>
        <div className="w-[50%] flex flex-col items-start justify-center">
          <label className="font-Avenir_regular text-lg">Field</label>
          <Spin wrapperClassName="full-width-spin" spinning={majorsLoading}>
            <Select
              className="black-border w-full h-[48px] rounded-[15px] !border-black focus:outline-none placeholder:!text-black placeholder-white/55"
              placeholder="Field"
              suffixIcon={<img src={DownArrowBlack} alt="dropdown-icon" />}
              options={
                majorsDropdown ? majorsDropdown : [{ label: "", value: "" }]
              }
              onSelect={(value: any) => {
                formik.setFieldValue("companyFieldId", value);
              }}
            />
          </Spin>
          {formik.touched.companyFieldId && formik.errors.companyFieldId && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.companyFieldId}
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">Job Type</label>
          <div className="flex flex-row items-center gap-x-3">
            {JOB_TYPES.map((item: any) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue("vacancyLevelId", item.id);
                    setSelectedJobType(item.id);
                  }}
                  className={`px-6 py-4 flex items-center justify-center border-2 border-dashboard_primary rounded-lg ${
                    selectedJobType === item.id
                      ? "bg-dashboard_primary"
                      : "bg-transparent"
                  }`}
                >
                  <p
                    className={`${
                      selectedJobType === item.id ? "text-white" : "text-black"
                    }`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
          {formik.touched.vacancyLevelId && formik.errors.vacancyLevelId && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.vacancyLevelId}
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">
            Job Location Type
          </label>
          <div className="flex flex-row items-center gap-x-3">
            {JOB_LOCATION.map((item: any) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue("VacancyWorkPlaceId", item.id);
                    setSelectedJobLocationType(item.id);
                  }}
                  className={`px-6 py-4 flex items-center justify-center border-2 border-dashboard_primary rounded-lg ${
                    selectedJobLocationType === item.id
                      ? "bg-dashboard_primary"
                      : "bg-transparent"
                  }`}
                >
                  <p
                    className={`${
                      selectedJobLocationType === item.id
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
          {formik.touched.VacancyWorkPlaceId &&
            formik.errors.VacancyWorkPlaceId && (
              <p className="text-[red] font-Avenir_regular">
                {formik.errors.VacancyWorkPlaceId}
              </p>
            )}
        </div>
        <div className="md:w-[70%] lg:w-[60%] flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">Skills Required</label>
          <Spin
            wrapperClassName="full-width-spin normal-height"
            className="!h-[48px]"
            spinning={skillsLoading}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                (option?.label ?? "").includes(input)
              }
              suffixIcon={<img src={DownArrowBlack} alt="down-arrow-icon" />}
              mode="multiple"
              onChange={(value: any) => {
                formik.setFieldValue("skillIds", value);
              }}
              options={skillsDropdown}
              className="black-border w-full !min-h-[48px] !rounded-[15px] !caret-black !text-black placeholder:!text-gray-400"
              placeholder="Search For Skills..."
            />
          </Spin>
          {formik.errors.skillIds && (
            <p className="text-[red] font-Avenir_regular">
              please enter this field
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-start">
          <div className="w-full flex flex-row items-center gap-x-3">
            <label className="font-Avenir_regular text-lg">
              Responsibilities
            </label>
            <Tooltip
              className="hover:cursor-pointer"
              title="It's recommended to use bullet points to describe the job's responsibilities"
            >
              <InfoCircleOutlined className="text-dashboard_gray" />
            </Tooltip>
          </div>
          <div className="w-full lg:w-[80%] min-h-[300px] lg:min-h-[200px] bg-white shadow-lg">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
            />
          </div>
          {formik.touched.responsibilities &&
            formik.errors.responsibilities && (
              <p className="text-[red] font-Avenir_regular">
                {formik.errors.responsibilities}
              </p>
            )}
        </div>
        <div className="w-full flex flex-col items-start">
          <div className="w-full flex flex-row items-center gap-x-3">
            <label className="font-Avenir_regular text-lg">
              Job Requirements
            </label>
            <Tooltip
              className="hover:cursor-pointer"
              title="It's recommended to use bullet points to describe the job's requirements"
            >
              <InfoCircleOutlined className="text-dashboard_gray" />
            </Tooltip>
          </div>
          <div className="w-full lg:w-[80%] min-h-[300px] lg:min-h-[200px] bg-white shadow-lg">
            <Editor
              editorState={editorState2}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState2}
            />
          </div>
          {formik.touched.requirements && formik.errors.requirements && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.requirements}
            </p>
          )}
        </div>

        <div className="md:w-[70%] lg:w-[50%] flex flex-col items-start gap-y-3">
          <label className="font-Avenir_regular text-lg">
            Registration Deadline
          </label>
          <DatePicker
            className="!h-[48px] rounded-[15px] bg-transparent w-full !border-black !text-black hover:!bg-transparent"
            placeholder="Registration Deadline"
            onChange={(date, dateString) => {
              formik.setFieldValue("deadline", dateString);
            }}
            disabledDate={(date) => date.isBefore(dayjs(), "day")}
          />
          {formik.touched.deadline && formik.errors.deadline && (
            <p className="text-[red] font-Avenir_regular">
              {formik.errors.deadline}
            </p>
          )}
        </div>

        <div className="w-full flex items-center justify-center">
          <Spin className="full-width-spin" spinning={createVacancyLoading}>
            <button
              type="button"
              onClick={() => {
                if (editorState) {
                  formik.setFieldValue(
                    "responsibilities",
                    convertToHTML(editorState?.getCurrentContent())
                  );
                }

                if (editorState2) {
                  formik.setFieldValue(
                    "requirements",
                    convertToHTML(editorState2?.getCurrentContent())
                  );
                }

                formik.submitForm();
              }}
              className="bg-dashboard_primary w-full rounded-lg px-6 py-3 mb-10"
            >
              <p className="text-white">Create Vacancy</p>
            </button>
          </Spin>
        </div>
      </form>
    </div>
  );
};

export default CreateVacancy;
